import { Container, Row, Col,Navbar,Image,Button} from 'react-bootstrap';

const Menu = ()=>(
    <div>
       <Container>
    <Row className="justify-content-center">
        <Col xs={12} sm={10} className='menu d-flex justify-content-between align-items-center'>
            <Navbar.Brand href="#home">
                <Image src="Logo Branca sem fundo - LEGALIZAPRO 1.png" />
            </Navbar.Brand>
            <Button variant="light" className='btnContato'>Entrar em Contato</Button>
        </Col>
    </Row>
</Container>
    </div>
);

export default Menu;