import {  Row, Col,Button} from 'react-bootstrap';

const SectionApresentacao = ()=>(
    <div>
            <Row className="contApresentacao justify-content-center align-items-center">
                <Col xs={12}>
                    <Row className=' justify-content-center'>
                        <Col xs={12} sm={8} md={6} lg={4}>
                            <h1 className='titleApresentacao'>Legalização e Regularização de obras e imóveis.</h1>
                        </Col>
                    </Row>
                    <Row className=' justify-content-center'>
                        <Col xs={12} sm={8} md={6} lg={4}>
                            <h2 className='subtitleApresentacao'>Nosso compromisso é oferecer soluções eficazes, personalizadas, e livre de preocupações.</h2>
                        </Col>
                    </Row>
                    <Row className=' justify-content-center'>
                        <Col xs={12} sm={8} md={6} lg={4}  className="text-center">
                            <Button variant="light" className='btnContato btnApresentacao'>Entrar em Contato</Button>
                        </Col>

                    </Row>
                </Col>
                 
            </Row>
        
    </div>
);

export default SectionApresentacao;
